import './style/Pangrams.css';

function Pangrams () {
    return (
        <div>
            <h1 id="title"> I wanna get to know you!</h1>
            <div id="pangrams">
                The brown fox jumps over the lazy dog.<br />
                Pack my box with five dozen liquor jugs.<br />
                The five boxing wizards jump quickly.<br />
                How vexingly quick daft zebras jump.<br />
            </div>
        </div>
    );
}

export default Pangrams;
